exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculadoras-calculadora-cdb-vs-lci-e-lca-js": () => import("./../../../src/pages/calculadoras/calculadora-cdb-vs-lci-e-lca.js" /* webpackChunkName: "component---src-pages-calculadoras-calculadora-cdb-vs-lci-e-lca-js" */),
  "component---src-pages-calculadoras-calculadora-juros-compostos-js": () => import("./../../../src/pages/calculadoras/calculadora-juros-compostos.js" /* webpackChunkName: "component---src-pages-calculadoras-calculadora-juros-compostos-js" */),
  "component---src-pages-calculadoras-calculadora-rendimento-liquido-cdb-js": () => import("./../../../src/pages/calculadoras/calculadora-rendimento-liquido-cdb.js" /* webpackChunkName: "component---src-pages-calculadoras-calculadora-rendimento-liquido-cdb-js" */),
  "component---src-pages-calculadoras-calculadora-saque-aniversario-fgts-js": () => import("./../../../src/pages/calculadoras/calculadora-saque-aniversario-fgts.js" /* webpackChunkName: "component---src-pages-calculadoras-calculadora-saque-aniversario-fgts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/posts/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-posts-markdown-remark-frontmatter-slug-js" */)
}

